import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    //
    // Standard
    //
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        meta: {
            isHome: true
        },
    },
    {
        path: '/agm',
        alias: [
            '/agm/'
        ],
        name: 'AGM',
        component: () => import('../views/AGM.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Notice of Annual General Meeting', to: '/agm' }
            ]
        }
    },
    {
        path: '/join-us',
        alias: [
            '/joinus'
        ],
        name: 'Join Us',
        component:  () => import('../views/JoinUs.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Join Us', to: '/join-us' }
            ]
        }
    },
    {
        path: '/about',
        name: 'About',
        component:  () => import('../views/About.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'About', to: '/about' }
            ]
        }
    },
    {
        path: '/contact-us',
        alias: [
            '/contactus'
        ],
        name: 'Contact',
        component:  () => import('../views/Contact.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Contact', to: '/contact-us' }
            ]
        }
    },
    {
        path: '/download-app',
        alias: [
            '/app'
        ],
        name: 'Download App',
        component:  () => import('../views/DownloadApp.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Download Our App', to: '/download-app' }
            ]
        }
    },
    {
        path: '/savings',
        name: 'Savings',
        component:  () => import('../views/savings/Savings.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Saving with Cash Box', to: '/savings' }
            ]
        }
    },
    {
        path: '/christmas-savings',
        alias: [
            '/savings/christmas-savings',
            '/christmas-saver'
        ],
        name: 'ChristmasSavings',
        component:  () => import('../views/savings/ChristmasSavings.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Christmas Savings', to: '/savings/christmas-savings' }
            ]
        }
    },
    {
        path: '/savings/prize-saver',
        name: 'PrizeSaver',
        component:  () => import('../views/savings/prize-saver/PrizeSaver.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Prize Saver', to: '/savings/prize-saver' }
            ]
        }
    },
    {
        path: '/savings/prize-saver/draws',
        name: 'PrizeSaverDraws',
        component:  () => import('../views/savings/prize-saver/PrizeSaverDraws.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Prize Saver', to: '/savings/prize-saver' },
                { text: 'Draws', to: '/savings/prize-saver/draws' }
            ]
        }
    },
    {
        path: '/savings/prize-saver/privacy-notice',
        name: 'PrizeSaverPrivacyNotice',
        component:  () => import('../views/savings/prize-saver/PrizeSaverPrivacyPolicy.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Prize Saver', to: '/savings/prize-saver' },
                { text: 'Privacy Policy', to: '/savings/prize-saver/privacy-policy' }
            ]
        }
    },
    {
        path: '/savings/prize-saver/terms-and-conditions',
        name: 'PrizeSaverTerms',
        component:  () => import('../views/savings/prize-saver/PrizeSaverTerms.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Prize Saver', to: '/savings/prize-saver' },
                { text: 'Terms & Conditions', to: '/savings/prize-saver/terms-and-conditions' }
            ]
        }
    },
    {
        path: '/loans',
        name: 'Loans',
        component:  () => import('../views/loans/Loans.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Loans', to: '/loans' }
            ]
        }
    },
    {
        path: '/loans/car-loan',
        alias: [
            '/loans/credit-union-car-loan'
        ],
        name: 'Car Loan',
        component:  () => import('../views/loans/CarLoan.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Loans', to: '/loans' },
                { text: 'Car Loan', to: '/loans/car-loan' }
            ]
        }
    },
    {
        path: '/loans/reserve-loan',
        alias: [
            '/loans/credit-union-reserve-loan'
        ],
        name: 'Reserve Loan',
        component:  () => import('../views/loans/ReserveLoan.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Loans', to: '/loans' },
                { text: 'Reserve Loan', to: '/loans/reserve-loan' }
            ]
        }
    },
    {
        path: '/loans/family-loan',
        alias: [
            '/loans/credit-union-family-loan'
        ],
        name: 'Family Loan',
        component:  () => import('../views/loans/FamilyLoan.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Loans', to: '/loans' },
                { text: 'Family Loan', to: '/loans/family-loan' }
            ]
        }
    },
    {
        path: '/loans/family-loan-plus',
        alias: [
            '/loans/credit-union-family-loan-plus'
        ],
        name: 'Family Loan Plus',
        component:  () => import('../views/loans/FamilyLoanPlus.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Loans', to: '/loans' },
                { text: 'Family Loan Plus', to: '/loans/family-loan-plus' }
            ]
        }
    },
    {
        path: '/loans/loan-protection-insurance',
        name: 'Loan Protection Insurance',
        component:  () => import('../views/loans/LoanProtectionInsurance.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Loans', to: '/loans' },
                { text: 'Loan Protection Insurance', to: '/loans/loan-protection-insurance' }
            ]
        }
    },
    {
        path: '/complaints',
        name: 'Complaints',
        component:  () => import('../views/Complaints.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Complaints', to: '/complaints' }
            ]
        }
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component:  () => import('../views/PrivacyPolicy.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Privacy Policy', to: '/privacy-policy' }
            ]
        }
    },
    {
        path: '/data-protection-rights',
        name: 'Data Protection Rights ',
        component:  () => import('../views/DataProtectionRights.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Data Protection Rights', to: '/data-protection-rights' }
            ]
        }
    },
    {
        path: '/help-and-support',
        alias: [
            '/useful-links'
            ],
        name: ' Help and Support',
        component:  () => import('../views/HelpAndSupport.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Help & Support', to: '/help-and-support' }
            ]
        }
    },

    {
        path: '/help-and-support/childcare-costs',
        name: 'HelpAndSupportChildcareCosts',
        component:  () => import('../views/help-and-support/Childcare.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Help & Support', to: '/help-and-support' },
                { text: 'Childcare Costs', to: '/help-and-support/childcare-costs' }
            ]
        }
    },
    {
        path: '/paypal-payment-complete',
        name: 'PayPal Payment Complete',
        component:  () => import('../views/webhook/PayPalCompleted.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'PayPal - Payment Complete', to: '/paypal-payment-complete' }
            ]
        }
    },
    //
    // Bee Bus Annual Ticket
    //
    {
        path: '/bee-network-annual-bus-pass',
        name: 'Annual Bee Bus Ticket',
        redirect: '/loans/bee-bus-annual-pass-loan'
    },
    {
        path: '/loans/bee-bus-annual-pass-loan',
        name: 'Annual Bee Bus Ticket Loan',
        component:  () => import('../views/loans/BeeBusAnnualPass.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Loans', to: '/loans' },
                { text: 'Annual Bee Bus Ticket Loan', to: '/loans/bee-bus-annual-pass-loan' }
            ]
        }
    },
    //
    // Extras
    //
    {
        path: '/loans/soundpound-loan',
        alias: [
            '/loans/soundpound'
        ],
        name: 'SoundPound Loan',
        component:  () => import('../views/loans/SoundPound.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Loans', to: '/loans' },
                { text: 'SoundPound Loan', to: '/loans/soundpound-loan' }
            ]
        }
    },
    {
        path: '/payroll-deduction-scheme',
        name: 'Payroll Deduction Scheme',
        component:  () => import('../views/payroll-deduction-scheme/PayrollDeductionScheme.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Payroll Deduction Scheme', to: '/payroll-deduction-scheme' }
            ]
        }
    },
    {
        path: '/payroll-deduction-scheme/what-is-it',
        name: 'Payroll Deduction Scheme What Is It',
        component:  () => import('../views/payroll-deduction-scheme/WhatIsIt.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Payroll Deduction Scheme', to: '/payroll-deduction-scheme' },
                { text: 'What is our Payroll Deduction Scheme', to: '/payroll-deduction-scheme/what-is-it' },
            ]
        }
    },
    {
        path: '/payroll-deduction-scheme/benefits-for-employees',
        name: 'Payroll Deduction Scheme Benefits for Employees',
        component:  () => import('../views/payroll-deduction-scheme/BenefitsEmployees.vue'),
        meta: {
            breadcrumb: [
                { text: 'Home', to: '/' },
                { text: 'Payroll Deduction Scheme', to: '/payroll-deduction-scheme' },
                { text: 'Benefits for Employees', to: '/payroll-deduction-scheme/benefits-for-employees' },
            ]
        }
    },
    {
        path: '/payroll-deduction-scheme/benefits-for-employers',
        name: 'Payroll Deduction Scheme Benefits for Employers',
        component: () => import('../views/payroll-deduction-scheme/BenefitsEmployers.vue'),
        meta: {
            breadcrumb: [
                {text: 'Home', to: '/'},
                {text: 'Payroll Deduction Scheme', to: '/payroll-deduction-scheme'},
                {text: 'Benefits for Employers', to: '/payroll-deduction-scheme/benefits-for-employers'},
            ]
        }
    },
    // {
    //     path: '/engage-card',
    //     name: 'Engage Card',
    //     component:  () => import('../views/EngageCard.vue'),
    //     meta: {
    //         breadcrumb: [
    //             { text: 'Home', to: '/' },
    //             { text: 'Engage Card', to: '/engage-card' }
    //         ]
    //     }
    // },
    //
    // 404
    //
    {
        path: '*',
        redirect: '/'
    },
]

const router = new VueRouter({
  mode: 'history',
  routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

export default router
