<template>
  <div class="family-loan">
    <h1>Cash Box Credit Union Family Loan <div class="small">Borrow up to &pound;750</div></h1>
    <p>
      The Cash Box Credit Union Family Loan lets you borrow for family expenses
      and use your child benefit to cover the repayments. Our Family Loan is here
      to help the people of Tameside and Glossop in an ethical and community based
      way. Working closely with our community, we pride ourselves on bringing fair
      and ethical finance to our members.
    </p>
    <video playsinline autoplay muted loop class="w-100 mb-2 rounded float-none float-md-right col-12 col-md-6">
      <source
          src="/video/family-loan.mp4"
          type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
    <h3>What do you need to do?</h3>
    <p>
      Below are the simple requirements to apply for our credit union Family Loan.
    </p>
    <ul>
      <li>Be aged 18 years old or over.</li>
      <li>
        In receipt of child benefit payments and agree to have this paid into your
        Cash Box Credit Union account
      </li>
      <li>
        Live or work in Tameside & Glossop or work for one of our Payroll Partners
      </li>
    </ul>
    <p>
      If you meet the above criteria, then our application process is very simple!
    </p>
    <div class="d-flex flex-column flex-md-row mb-3" style="gap:0.5rem;">
      <a href="https://cashbox.cuaccount.com/login/" target="_blank" class="btn btn-primary">Existing Members - Apply Here</a>
      <a href="https://cashbox.cuaccount.com/join/" target="_blank" class="btn btn-success">New Members</a>
    </div>
    <p>
      When applying for our Family Loan you become a member of our credit union family.  You will need to be in receipt of Child Benefit Payments and agree to have this paid to Cash Box Credit Union until your loan is repaid.</p>
    <p>The repayments can be arranged to suit what you can afford to pay.</p>
    <p>
      You decide how much of your child benefit is placed into your savings account, subject to a minimum of &pound;2 per week.
    </p>

    <p>What other benefits does the Cash Box Credit Union have?</p>
    <ul>
      <li>Our members are our family</li>
      <li>All applications are looked at by real people, not a computer</li>
      <li>The interest rate is fixed for the term of the loan</li>
      <li>No setup or early repayment fees</li>
      <li>Each payment you make adds a little more to your savings</li>
      <li>
        A loan with your local credit union will help you build or repair your
        credit rating
      </li>
    </ul>

    <h3>Need to borrow more than &pound;750?</h3>
    <p>
      Cash Box offers a range of loans designed to suit you. We offer our loans up
      to the value of &pound;15,000 and you can borrow as little as £50. We offer
      different types of loans so you always get the best value from your credit
      union.
    </p>

    <p>
      If you need more information on our credit union Family Loan, please contact
      our friendly staff on 0161 342 3266 or by emailing
      <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a>.
    </p>

    <hr />
    <p>
      Credit Unions are financial community co-operatives that promote savings as
      well as loans.
    </p>
    <p>
      Members of the credit union pool their savings to provide the funds from
      which loans are made. The interest on loans pays for the running of the
      credit union and if there is a surplus, members get a share of the profit
      known as a dividend.
    </p>
    <p>
      We expect all of our members to save as much as they can afford, even if
      they are repaying a loan. As a minimum, we would expect you to save at least
      £2 per week/£10 per month and keep this in your savings account while you
      have a loan outstanding with us. Once your savings balance is higher than
      your loan balance, you may if you wish withdraw the difference.
    </p>
  </div>
</template>

<script>
export default {
  name: 'FamilyLoan',
  metaInfo() {
    return {
      title: '£750 Credit Union Family Loan | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "Credit, Union, Family, Loan" },
        { name: 'description', content: "£750 Credit Union Family Loan" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
