<template>
  <div class="sound-pound">
    <h1>SoundPound Loan</h1>
    <p>Working closely with other Greater Manchester Credit Unions, we pride ourselves on bringing
      fair and ethical finance to members of our community.</p>
    <p>If you live in Tameside why not apply for a SoundPound Loan with Cash Box.</p>

    <dl class="row">
      <dt class="col-sm-5 col-md-4">Loan Amount:</dt>
      <dd class="col-sm-7 col-md-8">&pound;200 - &pound;1,000</dd>
      <dt class="col-sm-5 col-md-4">Frequency:</dt>
      <dd class="col-sm-7 col-md-8">Monthly</dd>
      <dt class="col-sm-5 col-md-4">Period:</dt>
      <dd class="col-sm-7 col-md-8">4 - 12 months</dd>
      <dt class="col-sm-5 col-md-4">Interest rate:</dt>
      <dd class="col-sm-7 col-md-8">42.6% APR</dd>
    </dl>

    <rates-table product-id="sound-pound"/>

    <h2>What do you need to do?</h2>
    <p>Below are the simple requirements to apply for a SoundPound Loan:</p>
    <ul>
      <li>Be aged 18 years old or over</li>
      <li>Be new to credit union membership</li>
    </ul>
    <p>You are more likely to be accepted if you have a regular source of income and would have some money left over after you have met the monthly loan repayments and you are mostly up-to-date with your existing credit commitments and not in too much debt already.</p>
    <p>You are not likely to be accepted if:</p>
    <ul>
      <li>You have had a number of defaults or a County Court Judgement (CCJ) in the last year</li>
      <li>If you are currently bankrupt or subject to an Individual Voluntary Arrangement (IVA) or Debt Relief Order (DRO)</li>
      <li>You already have a loan with a credit union</li>
    </ul>

    <p>If you meet the above criteria and live in Tameside...</p>

    <p>
      <b-button
        disabled
        @click="showModal = true"
        variant="success"
        size="lg">
        Apply for your SoundPound Loan
      </b-button>
<!--      <a-->
<!--          class="btn btn-lg btn-success"-->
<!--          href="https://cashbox.cuaccount.com/join/"-->
<!--          target="_blank"-->
<!--      >Apply for your SoundPound Loan</a-->
<!--      >-->
    </p>

    <b-modal
        v-model="showModal"
        scrollable
        size="xl"
        title="SoundPound Loan"
        hide-footer
        body-class="sound-pound-modal-body"
      >
    <iframe
        src="https://CashBox.cuaccount.com/loan/calculator/?q=TY1ZDsIgEIZvwzNlqz5wA29gjGEZlUgLAZro7QWN6bx8_Mswk0qAtZkW0qqjWX11JgPJqTaXPGhKwpq3pqff6-Fmtth0jcFDqSSX5Dc36n9zYpyYnOO7Z6nc9UQVJXbqzrIB3tGGbcO2rxUDckANzB0uxVS0klb6mbgHuOd1gcVC6R28XDxftKSC75ofUI5mOEUa52zXTKFcohztZ2g_m9E80uyIcvRXoFsc3RLiAw"
        frameborder="0"
        scrolling="no"
        allowfullscreen
        class="sound-pound-modal-iframe"
        style="width:100%;"></iframe>
    </b-modal>

    <p>When applying for our SoundPound loan you become a member of our credit union family.</p>
    <p>The repayments can be arranged to suit you.</p>
    <p>You keep a minimum of £5 in your Cash Box Savings Account to retain your membership.</p>
    <p>You agree to pay a minimum of £5 per month into your savings account.</p>

    <h3>What other benefits does Cash Box Credit Union offer?</h3>
    <p>Your credit union is a fair and ethical lender:</p>
    <ul>
      <li>Our members are our family</li>
      <li>All applications are looked at by real people, not a computer</li>
      <li>The interest rate is fixed for the term of the loan</li>
      <li>No setup or early repayment fees</li>
      <li>Each payment you make adds a little more to your savings</li>
      <li>A loan with your local credit union will help you build or repair your  credit rating</li>
    </ul>

    <p>If you need more information, please contact our friendly staff on <a href="tel:01613423266">0161 342 3266</a> or by emailing <a href="mailto:enquries@cashbox.org.uk">enquries@cashbox.org.uk</a>.</p>

  </div>
</template>

<script>
import RatesTable from "@/components/RatesTable";
export default {
  name: 'SoundPoundLoan',
  components: {RatesTable},
  metaInfo() {
    return {
      title: 'SoundPound Loan | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "Credit, Union, Sound, Pound, SoundPound, Loan, Greater Manchester, Credit Union, Credit Unions" },
        { name: 'description', content: "£200 to £1,000 SoundPound Loan from Cash Box Credit Union" }
      ],
    };
  },
  data() {
    return {
      showModal: false
    }
  }
}
</script>

<style>
.modal-body.sound-pound-modal-body {
  padding: 0.5rem 0;
}

.modal-body.sound-pound-modal-body .sound-pound-modal-iframe {
  height: 800px;
}

@media (max-width: 1199px) {
  .modal-body.sound-pound-modal-body .sound-pound-modal-iframe {
    height: 1050px;
  }
}

@media (max-width: 991px) {
  .modal-body.sound-pound-modal-body .sound-pound-modal-iframe {
    height: 1150px;
  }
}

@media (max-width: 767px) {
  .modal-body.sound-pound-modal-body .sound-pound-modal-iframe {
    height: 1250px;
  }
}

@media (max-width: 575px) {
  .modal-body.sound-pound-modal-body .sound-pound-modal-iframe {
    height: 1350px;
  }
}


</style>
