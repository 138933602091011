<template>
  <div
      class="calculator bg-light p-3 mb-3 rounded"
      v-if="selectedProduct != null"
  >
    <h3>Loan Calculator for {{ product.title }} Loan</h3>
    <p v-html="product.description.top"></p>
    <b-row class="py-1">
      <b-col cols="12" lg="4" class="d-flex align-items-center justify-content-between my-2 my-sm-none">
        <span>Loan Product</span>
        <span>{{ displayRate }}%</span>
      </b-col>
      <b-col cols="12" lg="8" class="d-flex align-items-center justify-content-between">
        <b-form-radio-group
            class="w-100 d-none d-sm-flex"
            :checked="selectedProduct"
            :options="loanProductsForRadioGroup"
            button-variant="outline-success"
            name="loan-products"
            buttons
            @input="(v) => productChanged(v)"
        ></b-form-radio-group>
        <b-form-select
            class="w-100 d-flex d-sm-none"
            v-model="selectedProduct"
            :options="loanProductsForRadioGroup"
            @input="(v) => productChanged(v)"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="py-1" v-if="product.rates.length > 1 && !ratesHaveMinAndMaxAmount">
      <b-col cols="12" md="4" class="d-flex align-items-center justify-content-between my-2 my-sm-none">
        <span>Rate</span>
        <span></span>
      </b-col>
      <b-col cols="12" md="8" class="d-flex align-items-center justify-content-between">
        <b-form-radio-group
            class="w-100 d-none d-sm-flex"
            v-model="selectedRate"
            :options="loanProductRatesForRadioGroup"
            button-variant="outline-success"
            name="loan-product-rates"
            buttons
        ></b-form-radio-group>
        <b-form-select
            class="w-100 d-flex d-sm-none"
            v-model="selectedRate"
            :options="loanProductRatesForRadioGroup"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="py-1">
      <b-col cols="12" md="4" class="d-flex align-items-center justify-content-between my-2 my-sm-none">
        <span>Amount</span>
        <span>{{ formatToCurrency(selectedAmount, 0) }}</span>
      </b-col>
      <b-col cols="12" md="8">
        <vue-slider
            v-model="selectedAmount"
            :min="product.amount.min"
            :max="product.amount.max"
            :interval="product.amount.step"
            tooltip="hover"
            :tooltip-style="{
              backgroundColor: '#fff',
              color: '#000',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }"
            :tooltip-formatter="formatToCurrency"
            :dotSize="28"
            :height="16"
            :silent="true"
            :rail-style="{ background: 'var(--cb-success-50)' }"
            :process-style="{ background: 'var(--cb-success)' }"
            :dot-style="{ background: 'var(--cb-success)', border: '4px solid white' }"
        />
      </b-col>
    </b-row>
    <b-row class="py-1">
      <b-col cols="12" md="4" class="d-flex align-items-center justify-content-between my-2 my-sm-none">
        <span>Period</span>
        <span>{{ formatToMonths(selectedPeriod) }}</span>
      </b-col>
      <b-col cols="12" md="8">
        <vue-slider
            v-model="selectedPeriod"
            :min="product.period.min"
            :max="product.period.max"
            :interval="product.period.step"
            tooltip="hover"
            :tooltip-style="{
              backgroundColor: '#fff',
              color: '#000',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }"
            :tooltip-formatter="formatToMonths"
            :dotSize="28"
            :height="16"
            :silent="true"
            :rail-style="{ background: 'var(--cb-success-50)' }"
            :process-style="{ background: 'var(--cb-success)' }"
            :dot-style="{ background: 'var(--cb-success)', border: '4px solid white' }"
        />
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col class="d-flex justify-content-between flex-column text-center text-md-left">
        <h5>Monthly Repayment</h5>
        <h2>{{ formatToCurrency(monthlyLoanPayment) }}</h2>
      </b-col>
      <b-col class="d-flex justify-content-between flex-column text-center text-md-left">
        <h5>Total Repayable</h5>
        <h2>{{ formatToCurrency(totalLoanRepayable) }}</h2>
      </b-col>
      <b-col cols="12" sm="12" md="4"
             class="d-flex align-items-center justify-content-center my-3 my-md-none">
        <a href="https://cashbox.cuaccount.com/loan/"
           target="_blank"
           class="btn btn-lg btn-success"
           v-b-tooltip.hover.top
           title="Existing Members Log In to Apply for a Loan"
        >APPLY NOW</a>
      </b-col>
    </b-row>
    <p class="p-0 m-0 text-center text-md-left" v-html="product.description.bottom"></p>
  </div>
</template>

<script>
import LoanProducts from "@/data/loan-products.js";
import { calculateLoanPayment, calculateLoanTotal } from "@/data/calculator";
import { formatToCurrency, formatToPercentage, formatToMonths } from "@/data/utils-number";

export default {
  name: "Calculator",
  data() {
    return {
      formatToCurrency,
      formatToPercentage,
      formatToMonths,
      calculateLoanPayment,
      calculateLoanTotal,
      loanProducts: LoanProducts,
      selectedProduct: null,
      selectedRate: null,
      selectedAmount: null,
      selectedPeriod: null,
    };
  },
  mounted() {
    let v = this.loanProducts[0].id;
    this.productChanged(v);
  },
  computed: {
    loanProductsForRadioGroup() {
      return this.loanProducts.filter(product => product.calculator === true).map((product) => {
        return {
          text: product.title,
          value: product.id,
        };
      });
    },
    loanProductRatesForRadioGroup() {
      return this.product.rates.map((rate) => {
        return {
          text: `${this.formatToPercentage(rate.monthly)} per month`,
          value: rate.value,
        };
      });
    },
    product() {
      return this.loanProducts.find(
          (product) => product.id === this.selectedProduct
      );
    },
    ratesHaveMinAndMaxAmount() {
      return this.product?.rates.some((rate) => rate.minAmount !== undefined && rate.maxAmount !== undefined);
    },
    displayRate() {
      if (this.ratesHaveMinAndMaxAmount) {
        let withinRange = this.product.rates.find((rate) => {
          return this.selectedAmount >= rate.minAmount && this.selectedAmount <= rate.maxAmount;
        });
        return withinRange?.display ?? 0;
      } else {
        if (this.product.rates.length > 1) {
          return this.product.rates.find((rate) => rate.value === this.selectedRate)?.display ?? 0;
        }
        return this.product.rates[0].display;
      }
    },
    getSelectedRate() {
      if (this.ratesHaveMinAndMaxAmount) {
        let withinRange = this.product.rates.find((rate) => {
          return this.selectedAmount >= rate.minAmount && this.selectedAmount <= rate.maxAmount;
        });
        return withinRange?.value ?? 0;
      } else {
        return this.selectedRate ?? this.product.rates[0].value;
      }
    },
    monthlyLoanPayment() {
      return this.calculateLoanPayment(
          this.selectedAmount,
          this.getSelectedRate,
          this.selectedPeriod);
    },
    totalLoanRepayable() {
      return this.calculateLoanTotal(
          this.selectedAmount,
          this.getSelectedRate,
          this.selectedPeriod);
    },
  },
  methods: {
    productChanged(v) {
      let product = this.loanProducts.find((product) => product.id === v);
      this.selectedAmount = product.amount.default;
      this.selectedPeriod = product.period.default;
      this.selectedRate = !this.ratesHaveMinAndMaxAmount && product.rates.length > 1 ? product.rates[0].value : null;
      this.selectedProduct = v;
    }
  }
}
</script>

<style scoped>

</style>