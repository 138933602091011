export default [
    {
        id: "standard",
        calculator: true,
        display: true,
        title: "Standard",
        description: {
            top: "Available to all members who have saved with Cash Box for a minimum of 13 weeks.",
            bottom: "Please note that these figures are for illustrative purposes only. For a personalised quotation or if you need to borrow more than &pound;6,000 please <a href=\"mailto:enquiries@cashbox.org.uk\">contact us</a>"
        },
        amount: {
            min: 250,
            step: 250,
            max: 6000,
            default: 2000,
            examples: [500, 1000]
        },
        period: {
            min: 6,
            step: 6,
            max: 48,
            default: 24,
            example: 12
        },
        rates: [{
            value: 30,
            display: 34.5,
            monthly: 2.5
        }]
    },
    {
        id: "secured",
        calculator: true,
        display: true,
        title: "Secured",
        description: {
            top: "Available to any member wishing to borrow an amount not exceeding their share balance.",
            bottom: "Please note that these figures are for illustrative purposes only. For a personalised quotation or if you need to borrow more than &pound;6,000 please <a href=\"/contact-us\">contact us</a>"
        },
        amount: {
            min: 250,
            step: 250,
            max: 6000,
            default: 2000,
            examples: [500, 1000]
        },
        period: {
            min: 6,
            step: 6,
            max: 48,
            default: 24,
            example: 12
        },
        rates: [{
            value: 12,
            display: 12.7,
            monthly: 1
        }]
    },
    {
        id: "payroll",
        calculator: true,
        display: true,
        title: "Payroll",
        description: {
            top: "Available to employees of companies who have a payroll deduction scheme in place.",
            bottom: "Please note that these figures are for illustrative purposes only. For a personalised quotation please <a href=\"/contact-us/\">contact us</a>."
        },
        amount: {
            min: 250,
            step: 50,
            max: 2000,
            default: 500,
            examples: [500, 1000]
        },
        period: {
            min: 1,
            step: 1,
            max: 24,
            default: 1,
            example: 12
        },
        rates: [
            {
                value: 12.01,
                display: 12.7,
                monthly: 1
            },
            {
                value: 30,
                display: 34.5,
                monthly: 2.5
            },
            {
                value: 36,
                display: 42.6,
                monthly: 3
            }
            ]
    },
    {
        id: "family",
        url: "/loans/family-loan",
        calculator: true,
        display: true,
        title: "Family",
        description: {
            top: "To be eligible for our Family Loan you must meet the criteria which you can <a href=\"/loans/family-loan\">view here</a>.",
            bottom: "Please note that these figures are for illustrative purposes only."
        },
        amount: {
            min: 50,
            step: 50,
            max: 750,
            default: 250,
            examples: [500]
        },
        period: {
            min: 1,
            step: 1,
            max: 12,
            default: 1,
            example: 12
        },
        rates: [{
            value: 36,
            display: 42.6,
            monthly: 3
        }]
    },
    {
        id: "sound-pound",
        calculator: false,
        display: false,
        title: "Sound Pound",
        description: {
            top: "To be eligible for our Family Loan you must meet the criteria which you can <a href=\"/loans/family-loan\">view here</a>.",
            bottom: "Please note that these figures are for illustrative purposes only."
        },
        amount: {
            min: 200,
            step: 50,
            max: 1000,
            default: 500,
            examples: [500, 1000]
        },
        period: {
            min: 4,
            step: 1,
            max: 12,
            default: 1,
            example: 12
        },
        rates: [{
            value: 36,
            display: 42.6,
            monthly: 3
        }]
    },
    {
        id: "car",
        url: "/loans/car-loan",
        calculator: true,
        display: true,
        title: "Car",
        description: {
            bottom: "Please note that these figures are for illustrative purposes only. For a personalised quotation please <a href=\"/contact-us/\">contact us</a>."
        },
        amount: {
            min: 3000,
            step: 1000,
            max: 10000,
            default: 3000,
            examples: [3000, 7000, 15000]
        },
        period: {
            min: 6,
            step: 6,
            max: 60,
            default: 24,
            example: 24
        },
        rates: [
            {
                value: 12.01,
                display: 12.7,
                monthly: 1
            }
        ]
    },
    {
        id: "reserve",
        url: "/loans/reserve-loan",
        calculator: true,
        display: true,
        title: "Reserve",
        description: {
            bottom: "Please note that these figures are for illustrative purposes only. For a personalised quotation please <a href=\"/contact-us/\">contact us</a>."
        },
        amount: {
            min: 1000,
            step: 1000,
            max: 15000,
            default: 3000,
            examples: [2000, 4000, 7000, 15000]
        },
        period: {
            min: 6,
            step: 6,
            max: 60,
            default: 24,
            example: 36
        },
        rates: [
            {
                value: 12.01,
                display: 12.7,
                minAmount: 1000,
                maxAmount: 2999
            },
            {
                value: 10.026,
                display: 10.5,
                minAmount: 3000,
                maxAmount: 4999
            },
            {
                value: 8,
                display: 8.3,
                minAmount: 5000,
                maxAmount: 7499
            },
            {
                value: 5.461,
                display: 5.6,
                minAmount: 7500,
                maxAmount: 15000
            }
        ]
    },
]