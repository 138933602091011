<template>
  <div class="loans">

    <h1>Why choose Cash Box for a loan?</h1>
    <ul class="pl-4">
      <li>We are a community-based organisation providing secure savings and low interest loans for its members.</li>
      <li>Interest is fixed for the period of the loan.</li>
      <li>There are no early settlement penalties if you are able to pay your loan off sooner than expected.</li>
      <li>Loans can be arranged from as little as &pound;50 to as much as &pound;15,000, depending on your level of savings and affordability.</li>
      <li>Interest is only charged on the balance outstanding and is calculated daily.</li>
    </ul>

    <p>Cash Box offers a range of loan products depending on your circumstances. For an illustration please follow the links below:-</p>

    <ul>
      <li v-for="product in products" :key="product.id">
        <a href="#" @click="e => scrollToProduct(e, product.id)">{{ product.name }}</a>
      </li>
    </ul>

    <div :id="product.id" class="loans__product" v-for="product in products" :key="product.id">
      <hr/>
      <h2>
        <template v-if="product.url">
          <router-link :to="product.url">{{ product.name }}</router-link>
        </template>
        <template v-else>
          {{ product.name }}
        </template>
      </h2>
      <p v-html="product.description.top"></p>
      <p v-if="product.rates">{{ product.name }}: {{ product.rates }}</p>
      <p>Representative Example: <span class="font-weight-bold">{{ product.representativeExample }}</span></p>
      <rates-table :product-id="product.id" />
      <p v-html="product.description.bottom"></p>
    </div>

    <hr/>
    <p>For more information call 0161 342 3266.</p>

    <hr />
    <p>Compare this to other lenders and you will see just how fair and reassuring dealing with Cash Box really is.</p>

    <p>If you meet the above criteria our application process is very simple!</p>

    <p>
      <a href="https://cashbox.cuaccount.com/login/" target="_blank" class="btn btn-primary mr-2">Existing Members</a>
      <a href="https://cashbox.cuaccount.com/join/" target="_blank" class="btn btn-success">New Members</a>
    </p>

    <hr />
    <p>Credit Unions are financial community co-operatives that promote savings as well as loans.</p>
    <p>Members of the credit union pool their savings to provide the funds from which loans are made. The
      interest on loans pays for the running of the credit union and if there is a surplus, members get
      a share of the profit known as a dividend.</p>
    <p>We expect all of our members to save as much as they can afford, even if they are repaying a
      loan. As a minimum, we would expect you to save at least £2 per week/£10 per month and keep
      this in your savings account while you have a loan outstanding with us.  Once your savings
      balance is higher than your loan balance, you may if you wish withdraw the difference.</p>

  </div>
</template>

<script>
import loanProducts from "@/data/loan-products";
import { calculateLoanPayment, calculateLoanTotal } from "@/data/calculator";
import { formatToPercentage } from "@/data/utils-number";
import RatesTable from "@/components/RatesTable";

export default {
  name: 'Loans',
  components: {RatesTable},
  metaInfo() {
    return {
      title: 'Loans | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  },
  data() {
    return {
      formatToPercentage,
      calculateLoanPayment,
      calculateLoanTotal,
      loanProducts,
    };
  },
  computed: {
    products() {
      return loanProducts.filter(product => product.display === true).map(product => {

        let monthlyRates = product.rates.map(rate => this.formatToPercentage(rate.monthly));
        let monthlyRatesDescription = monthlyRates.join(", ").replace(/,([^,]*)$/, " and$1")

        let hasMinAndMaxAmount = product.rates.some(rate => rate.minAmount !== undefined && rate.maxAmount !== undefined);

        let aprRates = product.rates.map(rate => {
          if (product.rates.length > 1) {
            if (rate.monthly) {
              return `${this.formatToPercentage(rate.monthly)} = APR ${this.formatToPercentage(rate.display)}`
            } else {
              return `APR ${this.formatToPercentage(rate.display)}`
            }
          } else {
            return `APR ${this.formatToPercentage(rate.display)}`
          }
        }).join(", ");

        return {
          id: product.id,
          url: product.url,
          name: `${product.title} Loan`,
          description: product.description,
          rates: !hasMinAndMaxAmount ? `Interest rate is ${monthlyRates.length > 1 ? 'subject to status' : ''} ${monthlyRatesDescription} per month.` : null,
          representativeExample:  aprRates,
          exampleTerm: product.period.example
        }
      });
    }
  },
  methods: {
    scrollToProduct(event, id) {
      event.preventDefault();
      let el = document.getElementById(id);
      el.scrollIntoView({behavior: 'smooth'});
    }
  }
}
</script>

<style scoped>

</style>
