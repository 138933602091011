<template>
  <b-card-group deck>
    <b-card class="p-0" body-class="d-flex flex-column align-items-center text-center">
      <b-img src="/images/callus.png" alt="Call Us" class="mb-2" />
      <h3>Call Us</h3>
      <p>Give us a call for more information about Cash Box.</p>
      <h3><a href="tel:01613423266" class="text-muted">0161 342 3266</a></h3>
    </b-card>
    <b-card class="p-0" body-class="d-flex flex-column align-items-center">
      <b-img src="/images/feedback.png" alt="Feedback" class="mb-2" />
      <h3>Feedback</h3>
      <a href="https://uk.1872culture.com/survey/member_always-on_temperature_check-cash_box_credit_union-temp-202407171346" target="_blank">
        <b-img rounded src="/misc/feedback-banner.png" alt="Feedback" fluid />
      </a>
    </b-card>
    <b-card class="p-0" body-class="d-flex flex-column align-items-center text-center">
      <b-img src="/images/findus.png" alt="Find Us" class="mb-2" />
      <h3>Follow Us</h3>
      <p>Follow Cash Box for all the up to date news and tips</p>
      <div class="d-flex social-icons">
        <a href="https://twitter.com/CashBoxTameside" target="_blank"><b-img src="/images/twitter.png" alt="Twitter"/></a>
        <a href="https://www.facebook.com/CashBoxCreditUnion/" target="_blank"><b-img src="/images/facebook.png" alt="Facebook"/></a>
      </div>
    </b-card>
  </b-card-group>
</template>

<script>
export default {
  name: "CallOrFindUs"
}
</script>

<style scoped>
.social-icons {
  gap: 0.5rem;
}
</style>