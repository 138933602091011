<template>
  <div class="loan-protection-insurance">
    <h1>Loan Protection Insurance</h1>
    <b-img fluid src="/images/cmutual.jpg" alt="CMutual"
           class="d-block mx-auto p-0 rounded float-md-right pl-md-3 col-12 col-sm-8 col-md-5 mb-4" />
    <p>
      Loan Protection is a CMutual insurance policy paid for by the Credit Union, which is used towards paying off an outstanding loan should the member die.
    </p>
    <p>
      Ultimately, this helps to protect our Credit Union’s financial position and removes a potential stress from member’s family, at what is an extremely difficult time.
    </p>
    <p> The benefit payable is based on the outstanding member’s loan balance at the time the member dies, subject to a maximum level set by the Credit Union:</p>
    <ul>
      <li>The maximum level of benefit for Cash Box borrowers is £1500. <sup>i</sup> </li>
      <li>Loan Protection Insurance is designed to provide complete peace of mind to our borrowers and depending on the size of loan, in most cases ensures that the debt dies with the debtor.</li>
      <li>Credit Unions across the world are leaders in offering members loans with “Built in Life cover”.  It has become a core part of the uniqueness that is Credit Unions. Credit Unions not only provide the member with the money they need, in a responsible and supportive manner, but also the reassurance that loan protection cover is in place.<sup>ii</sup></li>
      <li>Providing members with help, support and financial security where possible, is extremely important and is at the heart of the Credit Union ethos.</li>
    </ul>
    <hr/>
    <ul class="list-unstyled">
      <li class="small"><sup>i</sup> Our average loan balance @ 30.09.23 is £1309</li>
      <li class="small"><sup>ii</sup> Loan Protection Insurance Cover is subject to <a href="https://www.cmutual.co.uk/loanprotectioninsurance" target="_blank">CMutual Terms &amp; Conditions</a></li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'LoanProtectionInsurance',
  metaInfo() {
    return {
      title: 'Loan Protection Insurance | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "Credit, Union, Family, Loan" },
        { name: 'description', content: "Loan Protection Insurance" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
