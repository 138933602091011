<template>
  <div class="reserve-loan">
    <h1>Cash Box Credit Union Reserve Loan <div class="small">Borrow up to {{ borrowUpTo }} with rates starting from {{ startingRate }} APR</div></h1>
    <p>
      Working closely with our community, we pride ourselves on bringing fair and ethical finance to our members.
      If you have an excellent credit profile, and need a loan, a Reserve Loan lets you borrow up to {{ borrowUpTo }} with rates starting as low as {{ startingRate }} APR
    </p>
    <h3>How do I qualify?</h3>
    <p>
      Below are the simple requirements to apply for our credit union Reserve Loan.
    </p>
    <b-img fluid src="/images/reserve.png" alt="Moving House"
           class="d-block mx-auto p-0 rounded float-md-right pl-md-3 col-12 col-sm-8 col-md-5 mb-4" />
    <ul>
      <li>Be aged 18 years old or over.</li>
      <li>Have an excellent credit profile.</li>
      <li>Have a minimum income of &pound;24,000</li>
      <li>Live or work in Tameside &amp; Glossop or work for one of our Payroll Partners.</li>
    </ul>
    <p>
      If you meet the above criteria, then our application process is very simple!
    </p>
    <p>When applying for our Reserve Loan you will need to provide proof that you're a homeowner.</p>
    <p>
      The repayments can be arranged to suit you up to {{ maxYears }} years.
    </p>
    <div class="d-flex flex-column flex-md-row mb-3" style="gap:0.5rem;">
      <a href="https://cashbox.cuaccount.com/login/" target="_blank" class="btn btn-primary">Existing Members - Apply Here</a>
      <a href="https://cashbox.cuaccount.com/join/" target="_blank" class="btn btn-success">New Members</a>
    </div>

    <p>What other benefits does the Cash Box Credit Union have?</p>
    <ul>
      <li>Our members are our family</li>
      <li>All applications are looked at by real people, not a computer</li>
      <li>The interest rate is fixed for the term of the loan</li>
      <li>No setup or early repayment fees</li>
      <li>Free Loan Protection Cover up to £1500</li>
      <li>Save as you borrow - each payment you make adds a little more to your savings</li>
    </ul>

    <p>
      If you need more information on our credit union Reserve Loan, please contact
      our friendly staff on 0161 342 3266 or by emailing
      <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a>.
    </p>

    <hr />
    <p>
      Credit Unions are financial community co-operatives that promote savings as
      well as loans.
    </p>
    <p>
      Credit Union members pool their savings to provide the funds from which loans
      are made. The interest on loans pays for the running of the credit union and
      if there is a surplus, members get a share of the profit known as a dividend.
    </p>
    <p>
      We expect all of our members to save as much as they can afford, even if they
      are repaying a loan. As a minimum, we would expect you to save at least £2 per
      week/£10 per month and keep this in your savings account while you have a
      loan outstanding with us. Once your savings balance is higher than your loan
      balance, you may if you wish withdraw the difference.
    </p>
  </div>
</template>

<script>
import loanProducts from "@/data/loan-products";

export default {
  name: 'ReserveLoan',
  metaInfo() {
    return {
      title: 'Credit Union Reserve Loan | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "Credit, Union, Reserve, Loan" },
        { name: 'description', content: "£750 Credit Union Reserve Loan" }
      ],
    };
  },
  computed: {
    calculator() {
      return loanProducts.find(product => product.id === 'reserve');
    },
    borrowUpTo() {
      return this.calculator.amount.max.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
      });
    },
    startingRate() {
      let rates =  this.calculator.rates;
      rates = rates.sort((a, b) => a.display - b.display);
      return `${rates[0].display}%`;
    },
    maxYears() {
      return this.calculator.period.max / 12;
    }
  }
}
</script>

<style scoped>

</style>
