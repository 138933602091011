<template>
  <div class="prize-saver">
    <b-row>
      <b-col order="1" order-sm="1" order-md="0" class="text-center text-md-left">
        <h1>Prize Saver Draw Results</h1>
        <b-select
            size="lg"
            class="draw-selection mb-4 w-auto"
            v-model="selectedDraw"
            :options="draws"
            @input="selectedDraw = $event"
          />
        <div v-if="description" v-html="description" class="mb-0"></div>
      </b-col>
      <b-col order="0" order-sm="0" order-md="1" cols="10" offset="1" md="6" lg="4">
        <b-img
            fluid
            rounded
            src="/tiles/prizesaver.png"
            alt="Prize Saver"
            class="mb-3 mb-md-0"
          />
      </b-col>
    </b-row>
    <b-row  v-if="selectedDraw !== null" class="mt-3">
      <b-col
          cols="12"
          :lg="index === 0 ? 12 : 6"
          class="text-center prize mb-3"
          v-for="(prize, index) in details"
          :key="`prize-${index}`">
        <div
            class="h-100 border p-3 rounded"
            :class="{
              'border-success': index === 0
            }"
        >
        <h3 class="font-weight-bold text-success">{{ formatToCurrency(prize.amount, 0) }} Prize{{ prize.winners.length > 1 ? ` x ${prize.winners.reduce((a,c) => a + c.count, 0)}` : '' }}</h3>
        <div class="winners" v-for="(winner, index) in prize.winners" :key="`winner-${index}`">
          <h5>{{ winner.name }}{{ winner.count > 1 ? ` x ${winner.count}` : '' }}</h5>
        </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {prizeSaverDraw} from "@/data/prize-saver-draw";
import {formatToCurrency} from "../../../data/utils-number";

export default {
  name: 'PrizeSaverDraw',
  methods: {formatToCurrency},
  metaInfo() {
    return {
      title: 'Prize Saver - Draws | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "prize saver, prize, saver, cashbox, credit union, credit, union" },
        { name: 'description', content: "The credit union savings account with a chance to win £5,000 every month!" }
      ],
    };
  },
  data() {
    return {
      selectedDraw: null
    }
  },
  mounted() {
    this.selectedDraw = this.draws[0].value;
  },
  computed: {
    draws() {
      return prizeSaverDraw.map(x => {
        let date = new Date(`${x.date}-01`);
        let text = date.toLocaleDateString('en-GB', {month: 'long', year: 'numeric'});
        return {
          text: text,
          value: x.date,
          date: date
        }
      })
      .sort((a, b) => b.date - a.date)
      // limit to 12 months
      .slice(0, 12);
    },
    description() {
      return prizeSaverDraw
          .find(x => x.date === this.selectedDraw)
          .description;
    },
    details() {
      return prizeSaverDraw
          .find(x => x.date === this.selectedDraw)
          .prizes
          .map(x => {
            return {
              amount: x.amount,
              // group credit unions with total number appears
              winners: x.creditUnions.reduce((acc, val) => {
                let existing = acc.find(y => y.name === val);
                if (existing) {
                  existing.count++;
                } else {
                  acc.push({name: val, count: 1});
                }
                return acc;
              }, []).sort((a, b) => a.name.localeCompare(b.name))
            }
          });
    }
  }
}
</script>

<style scoped>

</style>
